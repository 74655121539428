class EventGenerator {
  generateEvents(measures, bpm) {
      const beatDuration = 60 / bpm; // Duration of one beat in seconds
      const events = [];

      measures.forEach((measure, measureIndex) => {
          measure.forEach((noteObj, noteIndex) => {
              if (noteObj.duration.includes('r')) {
                  return; // Skip rest notes
              }
              const time = (measureIndex * 4 + noteIndex) * beatDuration; // Correctly calculate time
              events.push({ note: noteObj.note, time });
          });
      });

      return events;
  }
}

export default EventGenerator;
