import React, { useEffect, useState, useImperativeHandle } from 'react';
import Vex from 'vexflow';
import './App.css';

const scrollAmount = 150; // Amount to scroll up or down
const NotationRenderer = React.forwardRef(({ measures }, ref) => {
  const [visibleSet, setVisibleSet] = useState(0);
  const measuresPerLine = 8; // Number of measures per line
  const linesToShow = 2; // Number of lines (each with 4 measures) to show

  useEffect(() => {
    renderNotation();
  }, [visibleSet, measures]);

  useImperativeHandle(ref, () => ({
    next: () => setTimeout(nextSet, 0),  // Defer state update
    previous: () => setTimeout(prevSet, 0),  // Defer state update
    reset: () => setTimeout(reset, 250),  // Defer state update
  }));

  const renderNotation = () => {
    const VF = Vex.Flow;
    const div = document.getElementById('notation');
    div.innerHTML = ''; // Clear the previous render
    const renderer = new VF.Renderer(div, VF.Renderer.Backends.SVG);
    const lines = measures.length % measuresPerLine == 0 ? measures.length / measuresPerLine : Math.floor(measures.length / measuresPerLine) + 1;
    renderer.resize(900, lines * scrollAmount); // Adjust height to accommodate all measures
    const context = renderer.getContext();
    context.setFont('Arial', 10, '').setBackgroundFillStyle('#eed');

    const staveWidth = 100;
    const staves = [];

    for (let i = 0; i < measures.length; i++) {
      const line = Math.floor(i / measuresPerLine);
      const staveIndex = i % measuresPerLine;

      const stave = new VF.Stave(10 + staveIndex * staveWidth, line * scrollAmount, staveWidth);
      if (i === 0) {
        stave.addClef('bass');
      }
      stave.setContext(context).draw();
      staves.push(stave);
    }

    for (let i = 0; i < measures.length; i++) {
      const measure = measures[i];
      const notes = measure.map((m) =>
        new VF.StaveNote({
          clef: 'bass',
          keys: [m.note.toLowerCase()[0] + "/" + (Number(m.note.toLowerCase()[1])+1)],
          duration: m.duration,
        })
      );

      const voice = new VF.Voice({ num_beats: 4, beat_value: 4 });
      voice.addTickables(notes);
      new VF.Formatter().joinVoices([voice]).format([voice], staveWidth - 20);
      voice.draw(context, staves[i]);
    }
  };

  const nextSet = () => {
    const totalLines = Math.ceil(measures.length / measuresPerLine);
    //if (visibleSet <= totalLines - linesToShow) {
    {
      setVisibleSet(visibleSet + 1);
      scrollNotation(-1 * scrollAmount); // Scroll down by one line
    }
  };

  const prevSet = () => {
    if (visibleSet > 0) {
      setVisibleSet(visibleSet - 1);
      scrollNotation(scrollAmount); // Scroll up by one line
    }
  };

  const reset = () => {
    scrollNotation(scrollAmount * visibleSet); // Reset scroll position
    setVisibleSet(0);
  }

  const scrollNotation = (offset) => {
    const notationDiv = document.getElementById('notation');
    const currentTransform = notationDiv.style.transform || 'translateY(0px)';
    const currentY = parseInt(currentTransform.match(/-?\d+/)[0], 10);
    notationDiv.style.transform = `translateY(${currentY + offset}px)`;
  };

  return (
    <div className="notation-wrapper">
        <div id="notation-container">
            <div id="notation">
                {/* Notation will be rendered here by vexflow */}
            </div>
        </div>
    </div>
);

});

export default NotationRenderer;
