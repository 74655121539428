import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './NoteErrorWidget.css';

const NoteErrorWidget = forwardRef((props, ref) => {
    const initialNoteStats = {
        '   ': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'G#2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'A2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'A#2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'B2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'C3': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        '  ': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'D#2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'E2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'F2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'F#2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'G2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        ' ': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'A#1': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'B1': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'C2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'C#2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'D2': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'E1': { expected: 0, played: 0, correct: 0, active: true, pulse: false },
        'F1': { expected: 0, played: 0, correct: 0, active: true, pulse: false },
        'F#1': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'G1': { expected: 0, played: 0, correct: 0, active: true, pulse: false },
        'G#1': { expected: 0, played: 0, correct: 0, active: false, pulse: false },
        'A1': { expected: 0, played: 0, correct: 0, active: true, pulse: false },
    };

    const [noteStats, setNoteStats] = useState(initialNoteStats);

    useImperativeHandle(ref, () => ({
        setActiveNotes: (activeNotes) => {
            setNoteStats((prevStats) => {
                const newStats = { ...prevStats };
                Object.keys(newStats).forEach((note) => {
                    newStats[note].active = activeNotes.includes(note);
                });
                return newStats;
            });
        },
        setCurrentNotes: (currentNotes) => {
            console.log(currentNotes);
            for (const noteIndex in currentNotes) {
                console.log(noteIndex);
                const note = currentNotes[noteIndex];
                console.log(note, note.target, note.played);
                handlePlayEvent(note.target,note.played);
            }
        },
        reset: () => {
            for (const note in noteStats) {
                noteStats[note].expected = 0;
                noteStats[note].played = 0;
                noteStats[note].correct = 0;
            }
        },
        getNoteStats: () => {
            let retv = [];
            console.log("noteStats: ",noteStats);
            console.log("noteStats keys: ",Object.keys(noteStats));
            for(let key in noteStats){
                const note = noteStats[key];
                if('expected' in note){
                if(noteStats[key].expected > 0){
                    retv.push({note:key,expected:noteStats[key].expected,played:noteStats[key].played});
                    }
                }
            }
            return retv;
        }
    }));

    // Method to handle play events
    const handlePlayEvent = (notesExpected, notesPlayed) => {
        const expected = notesExpected;
        const played = notesPlayed;
        
        if (expected && played) {
            setNoteStats(prevStats => {
                const newStats = { ...prevStats };
                
                // Update expected count
                newStats[expected].expected += 1;
                
                if (expected === played) {
                    newStats[expected].played += 1;
                    newStats[expected].correct += 1;
                } else if (played in newStats) {
                    
                    newStats[played].pulse = true; // Trigger pulse for incorrect note
                }

                return newStats;
            });
        }
    };

    // Temporary click handler to simulate F#2 pulse
    const handleClick = () => {
        setNoteStats(prevStats => ({
            ...prevStats,
            'F#2': { ...prevStats['F#2'], pulse: true }
        }));
    };

    useEffect(() => {
        const notesToPulse = Object.keys(noteStats).filter(note => noteStats[note].pulse);
        if (notesToPulse.length > 0) {
            const timer = setTimeout(() => {
                setNoteStats(prevStats => {
                    const newStats = { ...prevStats };
                    notesToPulse.forEach(note => {
                        newStats[note].pulse = false;
                    });
                    return newStats;
                });
            }, 200); // Reset pulse after 200ms
            return () => clearTimeout(timer);
        }
    }, [noteStats]);

    const calculatePercentage = (note) => {
        const { expected, played } = noteStats[note];
        return played > 0 ? (played/expected) * 100 : NaN;
    };

    const getCellClass = (percentage, active, pulse) => {
        if (pulse) return 'pulse';
        if (!active) return 'inactive';
        if (isNaN(percentage)) return '';
        if (percentage >= 95) return 'green';
        if (percentage >= 85) return 'yellow';
        if (percentage >= 75) return 'orange';
        return 'red';
    };

    return (
        <div className="note-error-widget">
            {Object.keys(noteStats).map((note, index) => {
                const percentage = calculatePercentage(note);
                const displayNote = note.replace(/\d+/, ''); // Remove octave number only for display
                const { active, pulse } = noteStats[note];
                return (
                    <div
                        key={index}
                        className={`note-cell ${getCellClass(percentage, active, pulse)}`}
                        onClick={note === 'F#2' ? handleClick : null} // Only add click handler to F#2
                    >
                        {active && (
                            <>
                                <span>{displayNote}</span>
                                <span>{!isNaN(percentage) ? `${percentage.toFixed(1)}%` : ''}</span>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
});

export default NoteErrorWidget;
