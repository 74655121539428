import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const API_URL = process.env.REACT_APP_API_URL;
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const handleGoogleSuccess = (credentialResponse) => {
        fetch(`${API_URL}/api/auth/google`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: credentialResponse.credential })
        })
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('user', JSON.stringify(data.user));
            navigate('/profile');
        })
        .catch(error => {
            console.error('Google Login failed', error);
            alert('Google Login failed');
        });
    };

    const handleGoogleError = (error) => {
        console.error('Google Login failed', error);
    };
    
    const handleLogin = (event) => {
        event.preventDefault();
        fetch(`${API_URL}/api/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                alert('Login failed: ' + data.error);
            } else {
                localStorage.setItem('user', JSON.stringify(data));
                navigate('/profile');
            }
        })
        .catch(error => {
            console.error('Login failed', error);
            alert('Login failed');
        });
    };

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <div className="login-container">
                <h2 className="login-title">Login</h2>
                <form onSubmit={handleLogin} className="login-form">
                    <div className="form-group">
                        <label htmlFor="email" className="form-label">Email:</label>
                        <input 
                            type="email" 
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                            className="form-input"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password" className="form-label">Password:</label>
                        <input 
                            type="password" 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                            className="form-input"
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
                <div className="google-login-container">
                    <h3 className="google-login-title">Or login with Google</h3>
                    <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleError}
                        className="google-login-button"
                    />
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default Login;
