// useDrumLoop.js
import { useRef, useCallback } from 'react';
import * as Tone from 'tone';

var source_link ={
  latency: 0,
  audioContext: null,
  cookie: "654",
  class: "clock"
};

export default function useDrumLoop() {
  const loopRef = useRef(null);

  const startLoop = useCallback((bpm) => {
    if (bpm) {
      Tone.Transport.cancel();
      Tone.Master.volume.value = -10;
      const kick = new Tone.MembraneSynth().toDestination();
      const snare = new Tone.NoiseSynth({
        noise: { type: 'white' },
        envelope: { attack: 0.005, decay: 0.1, sustain: 0 },
      }).toDestination();
      const hiHat = new Tone.MetalSynth({
        frequency: 200,
        envelope: { attack: 0.001, decay: 0.1, release: 0.01 },
        harmonicity: 5.1,
        modulationIndex: 32,
        resonance: 4000,
        octaves: 1.5,
      }).toDestination();

      const loop = new Tone.Loop((time) => {
        var event = { note: "Attack", time: time, cookie: "654", class: "clock" };
        if (source_link.on_event) {
          source_link.on_event(event);
        }
        kick.triggerAttackRelease('C2', '8n', time);
        snare.triggerAttackRelease('8n', time + (60 / bpm));
        hiHat.triggerAttackRelease('C5', '8n', time + (60 / bpm) * 2);
        snare.triggerAttackRelease('8n', time + (60 / bpm) * 3);
      }, '1m');

      Tone.Transport.bpm.value = bpm;
      loop.start(0);
      Tone.Transport.start('+0.1');
      loopRef.current = loop;

      console.log('Drum loop started with BPM:', bpm);
    } else {
      console.error('BPM is not provided or invalid.');
    }
    source_link.audioContext = Tone.context;
    return source_link;
  }, []);

  const stopLoop = useCallback(() => {
    if (loopRef.current) {
      loopRef.current.stop();
      Tone.Transport.stop();
      console.log('Drum loop stopped');
    }
  }, []);

  return { startLoop, stopLoop};
}
