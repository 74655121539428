import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Eyes from './Eyes';
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import NavBar from './NavBar';
import TrackPageView from './TrackPageView';

const App = () => {
    return (
        <Router>
            <TrackPageView />
            <NavBar />  {/* The strip at the top for login/profile */}
            <Routes>
                <Route path="/eyes" element={<Eyes />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </Router>
    );
};

export default App;
