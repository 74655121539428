import BaseEventProcessor from './BaseEventProcessor';

class EventProcessor extends BaseEventProcessor {
    constructor(audioContext) {
        super(audioContext);
        this.events = [];
        this.unansweredClockCount = 0;
        this.timingErrors = []; // Store timing errors here
        this.notesPlayed = []; // Store notes played here
    }

    processEvent(event) {
        const correctedEvent = this.correctTimestamp(event);
        if (!correctedEvent) return;

        this.events.push(correctedEvent);

        if (correctedEvent.class === 'clock') {
            this.unansweredClockCount++;
            this.checkForUnansweredClocks();
        } else if (correctedEvent.class === 'input') {
            this.processInputEvent(correctedEvent);
        }
    }

    processInputEvent(inputEvent) {
        let lastTargetEvent = { time: 0 };

        if (inputEvent.note === 'Rest') {
            return;
        }

        for (let i = 0; i < this.events.length; i++) {
            if (this.events[i].class === 'target') {
                lastTargetEvent = this.events[i];
            }
        }

        if (lastTargetEvent) {
            const timeDifference = inputEvent.time - lastTargetEvent.time;
            const timingErrorMs = timeDifference * 1000;

            // FIXME: we are sometimes detecting notes in the wrong octave
            if(inputEvent.note === lastTargetEvent.note){
                //compare everything but the last character
                if(inputEvent.note.slice(0,-1) === lastTargetEvent.note.slice(0,-1)){
                    inputEvent.note = lastTargetEvent.note;
                }
            }
                    

            // target notes are an  octave down from where they should be... fix it up
            if (inputEvent.note !== lastTargetEvent.note) {
                console.log('Incorrect note played:', inputEvent.note, "!= ", lastTargetEvent.note);
            }

            // Add the timing error to the list
            this.timingErrors.push(timingErrorMs);
            var noteInfo = { 'target': lastTargetEvent.note, 'played': inputEvent.note };
            console.log(noteInfo);
            this.notesPlayed.push(noteInfo);


            console.log('Time difference between target and input:', timingErrorMs + 'ms');

            this.unansweredClockCount = 0;  // Reset the unanswered clock count

            // You can add more logic here, such as triggering updates to the UI
        }
    }

    checkForUnansweredClocks() {
        if (this.unansweredClockCount >= 4) {
            this.triggerStopEvent();
        }
    }

    triggerStopEvent() {
        console.log('Stop event triggered after 4 unanswered clock events.');
        // Implement any additional logic for stopping
    }

    getTimingErrors() {
        return this.timingErrors;
    }
    getNotesPlayed() {
        return this.notesPlayed;
    }
}

export default EventProcessor;
