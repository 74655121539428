import React from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    return (
        <div>
            <h2>Profile</h2>
            {user ? (
                <div>
                    <p>You are currently logged in as: <span className='bold'>{user.email}</span></p>
                </div>
            ) : (
                <p>You are not logged in.</p>
            )}
        </div>
    );
};

export default Profile;
