export const frequencyToNote = (frequency) => {
  const notes = [
    "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"
  ];
  const A4 = 440;
  const noteNumber = 12 * (Math.log(frequency / A4) / Math.log(2));
  const noteIndex = Math.round(noteNumber) + 69;

  const note = notes[noteIndex % 12];
  const octave = Math.floor(noteIndex / 12) - 1;

  return `${note}${octave}`;
};
const Pitchfinder = require("pitchfinder");

export const detectPitch = (timeDomainData, sampleRate) => {
  const detectPitchYIN = Pitchfinder.YIN({ sampleRate });
  return detectPitchYIN(timeDomainData);
};

