import { useEffect, useRef, useState } from "react";
import { detectPitch, frequencyToNote } from "../util/pitch";

const useUserInputHandler = () => {
  const [userInputEvents, setUserInputEvents] = useState([]);
  const streamRef = useRef(null);
  const analyserRef = useRef(null);
  const bufferRef = useRef([]);
  var audioContext;
  var last_event = {
    note: "Rest",
    time: 0,
    cookie: "378",
    class: "input"
  };
  var source_link = {
    latency: 100,
    audioContext: null,
    cookie: "378",
    on_event: null,
    class: "input"
  };

  useEffect(() => {
    return () => {
      stopInput();
    };
  }, []);


  const startInput = (x) => {
    audioContext = x;
    if (navigator.mediaDevices && audioContext) {
      if (audioContext.state === 'suspended') {
        audioContext.resume().then(() => {
          console.log("Audio context resumed");
        });
      }

      navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
          latency: 0,
          autoGainControl: false,
          latencyHint:'interactive'
        }
      })
        .then((stream) => {
          streamRef.current = stream;
          const analyser = audioContext.createAnalyser();
          analyser.fftSize = 8192;
          analyserRef.current = analyser;
          const source = audioContext.createMediaStreamSource(stream);
          source.connect(analyser);
          processAudioData();
        })
        .catch((err) => {
          console.error("Error accessing audio stream:", err);
        });
    } else {
      console.error("No audio context or media devices available.");
    }
    source_link.audioContext = audioContext;
    return source_link;
  };

  const stopInput = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const processAudioData = () => {
    const analyser = analyserRef.current;
    if (!analyser) {
      console.error("Analyser node not initialized.");
      return;
    }

    const bufferLength = analyser.fftSize;
    const timeDomainData = new Float32Array(bufferLength);
    const silenceThreshold = 0.05;
    let lastPitch = null;
    let isRest = false;

    const processFrame = () => {
      analyser.getFloatTimeDomainData(timeDomainData);
      bufferRef.current.push(...timeDomainData);

      // Detecting note attack quickly using raw data
      const peakAmplitude = Math.max(...timeDomainData.map(Math.abs));
      var currentTime = audioContext.currentTime;

      if (peakAmplitude > silenceThreshold) {

        const zcr = calculateZCR(timeDomainData);
        if (zcr > 25) { // Immediate attack detection
          if (last_event.note === "Rest") {
            for(let i = 0; i < timeDomainData.length; i++) {
              if(timeDomainData[i] > silenceThreshold) {
                currentTime -= (timeDomainData.length-i) / audioContext.sampleRate;
                break;
              }
            }            
            last_event.note = "Attack";
            last_event.time = currentTime;
          }

          // setUserInputEvents((prevEvents) => [
          //   ...prevEvents,
          //   { note: "Attack(" + peakAmplitude +")", time: currentTime },
          // ]);
          isRest = false;
        }
      } else if (!isRest) {
        last_event = {
          note: "Rest",
          time: currentTime,
          cookie: "378",
          class: "input"
        }
        if (source_link.on_event) {
          source_link.on_event(last_event);
        }
        else {
          setUserInputEvents((prevEvents) => [
            ...prevEvents,
            { note: "Rest", time: currentTime, cookie: "378" },
          ]);
        }
        isRest = true;
      }

      // Feed accumulated buffer data to FFT
      if (bufferRef.current.length >= analyser.fftSize) {
        const pitch = detectPitch(bufferRef.current.slice(0, analyser.fftSize), audioContext.sampleRate);
        if (pitch !== null && pitch < 5000) {
          const note = frequencyToNote(pitch);
          if (note !== lastPitch || last_event.note === "Attack" || last_event.note === "Rest") {
            last_event.note = note;
            if (source_link.on_event) {
              source_link.on_event(last_event);
            }
            else {
              setUserInputEvents((prevEvents) => [
                ...prevEvents,
                last_event,
              ]);
          }
            lastPitch = note;
          }
        }
        bufferRef.current = []; // Clear the buffer after processing
      }

      requestAnimationFrame(processFrame);
    };

    processFrame();
  };

  const calculateZCR = (timeDomainData) => {
    let zcr = 0;
    for (let i = 1; i < timeDomainData.length; i++) {
      if ((timeDomainData[i - 1] >= 0 && timeDomainData[i] < 0) ||
        (timeDomainData[i - 1] < 0 && timeDomainData[i] >= 0)) {
        zcr++;
      }
    }
    return zcr;
  };

  return {
    userInputEvents,
    startInput,
    stopInput
  };
};

export default useUserInputHandler;

