import React, { useState, useEffect, useRef,useImperativeHandle } from 'react';
import useUserInputHandler from './useUserInputHandler';
import useDrumLoop from './useDrumLoop';
import EventProcessor from './EventProcessor';
import * as Tone from 'tone';
import NotationRenderer from './NotationRenderer';
import NotePlayer from './NotePlayer';
import EventGenerator from './EventGenerator';
import TimingErrorChart from './TimingErrorChart';
import NoteErrorWidget from './NoteErrorWidget';
import { v4 as uuidv4 } from 'uuid';

const datasets = [
  { value: 'dataset1', label: 'Open E Groove' },
  { value: 'Test', label: 'Test Set' },
  { value: 'E', label: 'Learning the fretboard - E string, 1st Position' },
  { value: 'A', label: 'Learning the fretboard - A string, 1st Position' },
  { value: 'EA', label: 'Learning the fretboard - E and A strings, 1st Position' },
  { value: 'D', label: 'Learning the fretboard - D string, 1st Position' },
  { value: 'EAD', label: 'Learning the fretboard - E, A, and D strings, 1st Position' },
  { value: 'G', label: 'Learning the fretboard - G string, 1st Position' },
  { value: 'EADG', label: 'Learning the fretboard - E, A, D and G strings, 1st Position' },
  { value: 'Neo', label: 'Prove your mastery' }
];

const API_URL = process.env.REACT_APP_API_URL;

const Eyes = () => {
    const [audioContext, setAudioContext] = useState(null);
    const [bpm, setBpm] = useState(80);
    const [measures, setMeasures] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState(datasets[0].value);
    const [cache, setCache] = useState({});
    const [activeNotes, setActiveNotes] = useState([]);

    const { userInputEvents, startInput, stopInput } = useUserInputHandler();
    const { startLoop, stopLoop } = useDrumLoop();
    const eventProcessorRef = useRef(null);
    const [running, setRunning] = useState(false); // Use state for running flag
    const eventGeneratorRef = useRef(null); // Ref to store EventGenerator instance

    const notationRef = useRef(null); // Ref to control NotationRenderer
    const playerRef = useRef(null); // Ref to store the NotePlayer instance
    const noteErrorRef = useRef(null); // Ref to access NoteErrorWidget

    const [currentMeasureIndex, setCurrentMeasureIndex] = useState(0);

    const [timingError, setTimingError] = useState([]); // Use state for timing errors
    
    const [playbackId, setPlaybackId] = useState(null);
    const [dataset, setDataset] = useState(null);

    var noteCount = 0;

    useEffect(() => {
      const fetchDataset = async (dataset) => {
          if (cache[dataset]) {
              // Use cached data
              const cachedData = cache[dataset];
              setBpm(cachedData.bpm);
              setMeasures(cachedData.measures);
              noteErrorRef.current.setActiveNotes(cachedData.notes);
          } else {
              // Fetch data from the backend
              const response = await fetch(`${API_URL}/api/notes?dataset=${dataset}`,{credentials: 'include'});
              const data = await response.json();
              setBpm(data.bpm);
              setMeasures(data.measures);
              noteErrorRef.current.setActiveNotes(data.notes); // Set active notes
              // Cache the data
              setCache((prevCache) => ({ ...prevCache, [dataset]: data }));
          }
          setDataset(dataset);
      };

      fetchDataset(selectedDataset);
  }, [selectedDataset, cache]);

    const next = () => {
        if (notationRef.current) {
            notationRef.current.next();
        }
    };
    
    const previous = () => {
        if (notationRef.current) {
            notationRef.current.previous();
        }
    };

    const handleMeasurePlayed = () => {
        setCurrentMeasureIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex % 8 === 0) {
                next(); // Call next every 4 measures
            }
            return newIndex;
        });
    };

    const calculateMean = (arr) => {
        const sum = arr.reduce((acc, val) => acc + val, 0);
        return sum / arr.length;
    };

    const calculateStdDev = (arr, mean) => {
        const variance = arr.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / arr.length;
        return Math.sqrt(variance);
    };

    const updateStats = ()=>{
        if (timingError.length === 0) {
            return;
        }
        const note_stats = noteErrorRef.current.getNoteStats();
        const mean = calculateMean(timingError);
        const low_stats = timingError.filter((x) => x < mean);
        const high_stats = timingError.filter((x) => x >= mean);
        const low_mean = calculateMean(low_stats);
        const high_mean = calculateMean(high_stats);
        const low_stdDev = calculateStdDev(low_stats, low_mean);
        const high_stdDev = calculateStdDev(high_stats, high_mean);
        const playback_stats = {
            mean: mean,
            stdDev: calculateStdDev(timingError, calculateMean(timingError)),
            low_mean: low_mean,
            low_stdDev: low_stdDev,
            high_mean: high_mean,
            high_stdDev: high_stdDev,
            dataset: dataset,
            playbackId: playbackId,
            bpm: bpm,
            notes: note_stats
        }
        fetch(`${API_URL}/api/playbackStats`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(playback_stats)
        })
        .then(response => response.json())
    };

    const handleFinish = () => {
        console.log('Playback finished');            
        stopLoop();
        stopInput();
        setRunning(false); // Update state
        updateStats();
        notationRef.current.reset();
    };

    const handleStartStop = async () => {
        if (running) {
            stopLoop();
            stopInput();
            setRunning(false); // Update state
            console.log('Processing stopped');
            updateStats();
            notationRef.current.reset();
            return;
        }
        setTimingError([]); // Clear timing errors
        setCurrentMeasureIndex(0); // Reset measure index
        noteErrorRef.current.reset(); // Reset note errors
        //generate a uuid for the playback id
        setPlaybackId(uuidv4());
        setRunning(true); // Update state before starting
        await Tone.start();
        console.log('Tone.js started');

        if (!audioContext) {
            const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            await newAudioContext.resume();
            setAudioContext(newAudioContext);
            eventProcessorRef.current = new EventProcessor(newAudioContext);
            console.log('AudioContext resumed');

            const inputSource = startInput(newAudioContext);
            eventProcessorRef.current.connectSource(inputSource);
            playerRef.current = NotePlayer({ measures, bpm, newAudioContext }); // Initialize NotePlayer with the measures, bpm, and audioContext
        } else {
            const inputSource = startInput(audioContext);
            eventProcessorRef.current.connectSource(inputSource);
            playerRef.current = NotePlayer({ measures, bpm, audioContext }); // Initialize NotePlayer with the measures, bpm, and audioContext
        }

        eventGeneratorRef.current = new EventGenerator(); // Correctly instantiate EventGenerator
        const events = eventGeneratorRef.current.generateEvents(measures, bpm);

        const drumSource = startLoop(bpm);
        eventProcessorRef.current.connectSource(drumSource);

        const noteSource = playerRef.current.playNotes(events, handleMeasurePlayed, handleFinish);
        eventProcessorRef.current.connectSource(noteSource);

        console.log('Busta Groove!');

        // Start polling for timing errors and update the UI
        const interval = setInterval(() => {
            if (eventProcessorRef.current) {
                const errors = eventProcessorRef.current.getTimingErrors();
                setTimingError(errors);
                const notesPlayed = eventProcessorRef.current.getNotesPlayed();
                if(notesPlayed.length > noteCount){
                  
                  var toSend = notesPlayed.slice(noteCount);
                  console.log("sending ", toSend.length, "new notes");
                  noteCount = notesPlayed.length;
                  noteErrorRef.current.setCurrentNotes(toSend);
                }
            }
        }, 30); // Polling interval in milliseconds

        return () => clearInterval(interval); // Clear interval when component unmounts or stops
    };


    return (
      <div className="sight-reading-container">
          <h1 className="title">Fretboard POC</h1>
          <p>Click start and play along with your bass guitar.</p>
          <br/>
          <div className="controls">
              <button onClick={handleStartStop} className="control-button">
                  {running ? 'Stop' : 'Start'}
              </button>
              <div className="bpm-control">
                  <label htmlFor="bpm" className="bpm-label">BPM</label>
                  <input
                      type="number"
                      id="bpm"
                      value={bpm}
                      onChange={(e) => setBpm(e.target.value)}
                      className="bpm-input"
                  />
              </div>
              <div className="dataset-control">
                    <label htmlFor="dataset" className="dataset-label">Select Dataset</label>
                    <select
                        id="dataset"
                        value={selectedDataset}
                        onChange={(e) => setSelectedDataset(e.target.value)}
                        className="dataset-select"
                    >
                        {datasets.map((dataset, index) => (
                            <option key={index} value={dataset.value}>
                                {dataset.label}
                            </option>
                        ))}
                    </select>
                </div>
          </div>
          <NotationRenderer ref={notationRef} measures={measures} />
          <NoteErrorWidget ref={noteErrorRef} />
          <TimingErrorChart errors={timingError} />
      </div>
    );
};

export default Eyes;