import React from 'react';

const Home = () => {
    return (
        <div className="home-container">
            <header className="home-header">
                <h1 className="home-title">Welcome to Groovy Ninja!</h1>
                <p className="section-content">Simple tools to help you master the bass guitar</p>
            </header>

            <section>
                <h2 className="section-title">About the Groovy Tools</h2>
                <p className="section-content">
                    I started learning bass earlier this year, diving into the 
                    <a href="https://bassbuzz.com" target="_blank" class="link"> Beginner to Badass</a> course from BassBuzz.com and I'm having a blast!
                    I then added the
                    <a href="https://talkingbass.net" target="_blank" class="link"> Simple Steps to Sight Reading</a> course from TalkingBass.net to enhance my skills.
                </p>
                <p className="section-content">
                    What I quickly realized is that to have the mental space to sight-read, or to play bass well at all, you need to "know the neck" of the instrument well enough that you can consistently hit the right note by muscle memory. To speed up building this crucial skill, I built the tools you'll find here.
                    They’ve been a game changer for me, and I'm excited to share them in the hopes they help you too.
                </p>
            </section>

            <section>
                <h2 className="section-title">Support Groovy Ninja</h2>
                <p className="section-content">
                    If you find these tools helpful, consider supporting the site on
                    <a href="https://patreon.com/GroovyNinja" target="_blank" class="link"> Patreon</a>.
                    Your support helps keep the project going and growing.
                </p>
                <p className="section-content">
                    Have questions or feedback? Feel free to send me a DM on Patreon or post in the forum thread where you found the link to this site.
                </p>
            </section>
        </div>
    );
};

export default Home;
