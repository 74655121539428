import * as Tone from 'tone';

const NotePlayer = ({ measures, bpm }) => {
  const synth = new Tone.Synth().toDestination();

  const playNotes = (events, onMeasurePlayed, onFinish) => {
    var source_link = {
      latency: 0,
      audioContext: null,
      cookie: "8088",
      class: "target",
      on_event: null // This will be set when connecting to the EventProcessor
    };

    Tone.Transport.bpm.value = bpm;
    source_link.audioContext = Tone.context;
    let measureCount = 0;
    console.log('Playing notes:', events);

    events.forEach((event, index) => {
      Tone.Transport.schedule((time) => {
        //console.log('Playing note:', event.note, 'scheduled at time:', event.time, 'actual time:', time);
        synth.triggerAttackRelease(event.note, '8n', time);

        // Send an event when the note is played
        if (source_link.on_event) {
          source_link.on_event({
            type: 'note',
            note: event.note,
            time: time,
            cookie: source_link.cookie,
            class: source_link.class
          });
        }

        // Calculate the measure end time
        const measureEndTime = (measureCount + 1) * 4 * (60 / bpm);
        const timeRemainingInMeasure = measureEndTime - event.time;

        if (event.time + 0.0001 >= measureEndTime) {
          measureCount++;
          if (onMeasurePlayed) onMeasurePlayed(); // Call the callback after each measure
        }

        // If this is the last event, schedule the onFinish callback
        if (index === events.length - 1 && onFinish) {
          Tone.Transport.scheduleOnce(() => {
            console.log('Finished playing notes at the end of the last measure');
            onFinish();
          }, `+${timeRemainingInMeasure}`);
        }
      }, `+${event.time}`); // Schedule using a relative time string
    });

    return source_link;
  };

  return { playNotes };
};

export default NotePlayer;
