import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is logged in by looking for user data in localStorage
        const user = localStorage.getItem('user');
        if (user) {
            setIsLoggedIn(true);
        }
    }, []); // Run this effect only once when the component mounts

    const handleLogout = () => {
        localStorage.removeItem('user'); // Remove user data from localStorage
        setIsLoggedIn(false); // Update the state to reflect that the user is logged out
    };

    return (
        <nav className="nav">
            <div className="nav-container">
                <Link to="/" className='nav-link'>Home</Link>
                <Link to="/eyes" className='nav-link'>1st position</Link>
                {isLoggedIn && <Link to="/profile" className='nav-link'>Profile</Link>}
                {!isLoggedIn 
                    ? <Link to="/login" className='nav-link'>Login</Link>
                    : <button onClick={handleLogout} className="nav-link">Logout</button>}
            </div>
        </nav>
    );
};

export default NavBar;
