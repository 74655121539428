import React from 'react';
import './TimingErrorChart.css';

const TimingErrorChart = ({ errors }) => {
    const ranges = {
        green: { min: -10, max: 10 },
        yellow: { min: -20, max: 20 },
        red: { min: -200, max: 200 }
    };

    const getErrorColor = (error) => {
        if (error >= ranges.green.min && error <= ranges.green.max) {
            return 'green';
        } else if (error >= ranges.yellow.min && error <= ranges.yellow.max) {
            return 'yellow';
        } else if (error >= ranges.red.min && error <= ranges.red.max) {
            return 'red';
        }
        return 'gray';
    };

    // Calculate mean and standard deviation
    const calculateMean = (arr) => {
        const sum = arr.reduce((acc, val) => acc + val, 0);
        return sum / arr.length;
    };

    const calculateStdDev = (arr, mean) => {
        const variance = arr.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / arr.length;
        return Math.sqrt(variance);
    };

    const mean = calculateMean(errors);
    const stdDev = calculateStdDev(errors, mean);

    const meanPosition = ((0 + ranges.red.max) / (ranges.red.max - ranges.red.min)) * 100;
    const stdDevPlusPosition = (((0 + stdDev) + ranges.red.max) / (ranges.red.max - ranges.red.min)) * 100;
    const stdDevMinusPosition = (((0 - stdDev) + ranges.red.max) / (ranges.red.max - ranges.red.min)) * 100;

    return (
        <div className="timing-error-widget">
            {errors.map((error, index) => {
                const errorColor = getErrorColor(error - mean);
                const yPosition = (index / errors.length) * 100;
                const xPosition = ((error + ranges.red.max - mean) / (ranges.red.max - ranges.red.min)) * 100;
    
                return (
                    <div
                        key={index}
                        className="error-point"
                        style={{
                            backgroundColor: errorColor,
                            left: `${xPosition}%`,
                            top: `${yPosition}%`,
                        }}
                    />
                );
            })}
    
            {/* Mean Line */}
            <div
                className="mean-line"
                style={{
                    left: `${meanPosition}%`,
                }}
            />
            <div className="label" style={{ left: `${meanPosition}%`, top: '-20px' }}>
                {Math.round(mean)} ms
            </div>
    
            {/* Std Dev Lines */}
            <div
                className="std-dev-line"
                style={{
                    left: `${stdDevPlusPosition}%`,
                }}
            />
            <div className="label" style={{ left: `${stdDevPlusPosition}%`, top: '-20px' }}>
                {Math.round(mean + stdDev)} ms
            </div>
    
            <div
                className="std-dev-line"
                style={{
                    left: `${stdDevMinusPosition}%`,
                }}
            />
            <div className="label" style={{ left: `${stdDevMinusPosition}%`, top: '-20px' }}>
                {Math.round(mean - stdDev)} ms
            </div>
        </div>
    );
};

export default TimingErrorChart;
