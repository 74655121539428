import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

// Initialize Google Analytics with your Measurement ID
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-FJEJEX68KC'); // Replace with your Google Analytics Measurement ID
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: Track page views on initial load
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

// You can also track performance vitals here (optional)
reportWebVitals();
