class BaseEventProcessor {
    constructor(audioContext) {
        this.audioContext = audioContext;
        this.sources = [];
    }

    connectSource(source) {
        console.log('Source connected:', source);
        const src_obj = { 
            cookie: source.cookie, 
            latency: source.latency,
            time_adj: source.audioContext.currentTime - this.audioContext.currentTime
        };
        source.on_event = this.processEvent.bind(this);
        this.sources.push(src_obj);
    }

    disconnectSource(source) {
        this.sources = this.sources.filter(s => s.cookie !== source.cookie);
        console.log('Source disconnected:', source);
    }

    correctTimestamp(event) {
        const source = this.sources.find(s => s.cookie === event.cookie);
        if (!source) {
            console.error('Source not found for event:', event);
            return null;
        }
        let timestamp = event.time - source.latency / 1000;
        var retv = new Object(event)
        retv.time = timestamp;
        return retv
    }
}

export default BaseEventProcessor;
